import React, { useEffect, useState } from 'react';
import { Paper, List, ListItemButton, ListItemText, Grid, Button, TextField, Typography } from '@mui/material';
import { deleteLocationChange, getLatestLocationChanges, saveCurrentLocation, saveLocationChange } from '../utils/db_helper';
import LocationMap from './LocationMap';
import util from '../utils/util'
import { useSnackbar } from 'notistack';

import 'leaflet/dist/leaflet.css';

const N_DAYS = 30*3;

export default function ManageLocationHistory({ theme }) {
    const [manualCreatorShowing, setManualCreatorShowing] = useState(false);
    const [form, setForm] = useState({
        lat_and_lon: "",
        city: "",
        country: "",
        isoDate: "",
        id: "",
    })
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [changes, setChanges] = useState([])
    useEffect(() => {
        const cutoff = util.timestamp() - 60*60*24*N_DAYS
        getLatestLocationChanges(30, cutoff).then((docs) => {
            setChanges(docs)
        })
    }, [])

    const saveLocation = () => {
        util.getCurrentLocation((latitude, longitude) => {
            saveCurrentLocation(latitude, longitude).then((res) => {
                enqueueSnackbar(`Current location updated - lat=${latitude}, lon=${longitude}`)
            })
        }, (error) => {
            enqueueSnackbar("Error saving location")
        })
    }

    const saveManualLocationChange = () => {
        const [lat, lon] = form.lat_and_lon.split(",")
        const isoDate = form.isoDate
        if (lat && lon && isoDate) {
            saveLocationChange(lat, lon, form.city, form.country, isoDate)
            setManualCreatorShowing(false)
        } else {
            enqueueSnackbar("Please fill in all fields")
        }
    }

    const doDeleteLocationChange = () => {
        const id = form.id
        if (id) {
            deleteLocationChange(id)
        }
    }

    const handleLocationClick = (id) => {
        util.saveToClipboard(id)
        enqueueSnackbar(`Location ID ${id} copied to clipboard`)
    }

    return (
        <div>

            <h3>Locations (last {N_DAYS} days)</h3>

            <Button onClick={saveLocation}>Save Current Location</Button>

            <Grid container>
                <Grid item xs={6}>
                    <Paper>
                        <List>
                            { changes.map((doc, i) => {
                                let loc = doc.data()
                                let text = `${loc.city}, ${loc.country}`
                                let first = i === 0;
                                if (first) text += " (latest)";
                                return (
                                    <ListItemButton key={doc.id} onClick={() => handleLocationClick(doc.id)}>
                                        <ListItemText primary={text} secondary={loc.date} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Paper>

                    <div>
                        { !manualCreatorShowing && <Button onClick={() => setManualCreatorShowing(true)}>Show Manual Location Change Form</Button> }
                        { manualCreatorShowing && <div>
                            <Typography variant="h6">Save Manual Location Change</Typography>
                            <TextField label="Latitude,Longitude" size="small" placeholder="Latitude,Longitude" value={form.lat_and_lon} onChange={(e) => setForm({...form, lat_and_lon: e.target.value})} />
                            <TextField label="City" size="small" placeholder="City" value={form.city} onChange={(e) => setForm({...form, city: e.target.value})} />
                            <TextField label="Country" size="small" placeholder="Country" value={form.country} onChange={(e) => setForm({...form, country: e.target.value})} />
                            <TextField label="ISO Date" size="small" placeholder="ISO Date" value={form.isoDate} onChange={(e) => setForm({...form, isoDate: e.target.value})} />
                            <Button onClick={saveManualLocationChange}>Save</Button>
                            <Typography variant="h6">Or, Delete Manual Location Change</Typography>
                            <TextField name="id" label="ID to delete" size="small" value={form.id} onChange={(e) => setForm({...form, id: e.target.value})} />
                            <Button onClick={doDeleteLocationChange} style={{marginTop: 10}} color="error" disabled={!form.id}>Delete</Button>
                        </div> }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <LocationMap locations={changes} />
                </Grid>
            </Grid>

        </div>
    );
}
